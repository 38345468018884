<template>
  <!-- <transition name="fade" mode="out-in"> -->

  <div class="screen-wrap">
    <div class="d-flex flex-column pa-5">
      <div class="text-center">
        <div class="text-h6">You Got</div>
        <div v-if="star > 0">{{ star }} Blue Stars</div>
        <div v-else>No blue Star</div>
      </div>

      <div class="d-flex justify-center my-2">
        <div class="mx-2">
          <v-icon
            :color="star > 0 ? 'blue darken-2' : 'blue lighten-4'"
            x-large
          >
            mdi-star</v-icon
          >
          <div class="text-center text-h6">150</div>
        </div>
        <div class="mx-2">
          <v-icon
            :color="star > 1 ? 'blue darken-2' : 'blue lighten-4'"
            x-large
          >
            mdi-star</v-icon
          >
          <div class="text-center text-h6">200</div>
        </div>
        <div class="mx-2">
          <v-icon
            :color="star > 2 ? 'blue darken-2' : 'blue lighten-4'"
            x-large
          >
            mdi-star</v-icon
          >
          <div class="text-center text-h6">250</div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <div class="px-2">{{ $t("string.gameScore") }} :</div>
        <div class="px-2">{{ score }}</div>
      </div>
      <div class="d-flex justify-center">
        <AbstractAvatar
          :avatar="auth.Player.avatar"
          :height="150"
          :width="150"
          class=""
        ></AbstractAvatar>
      </div>
      <!-- <div v-if="star == 0" class="text-center d-flex mx-auto pa-3">
        <span class="text-h6">-</span>
        <v-img
          contain
          width="30"
          :src="require('@/assets/item/C000030.png')"
        ></v-img>
      </div> -->
      <v-btn v-if="star > 0" class="text-h6" block large @click="play()">
        Well Done
      </v-btn>
      <v-btn v-else class="text-h6" block large @click="play()"> OK </v-btn>
    </div>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    game: (state) => state.gameCache.data,
  }),
  props: ["callbackStart"],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    submissionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    star: 1,
    score: 0,
    schoolYear: 0,
  }),
  created() {
    this.gameApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/dailyGame/move";

    this.gameApi.callbackReset = () => {
      this.gameApi.isLoading = true;
      this.gameApi.isError = false;
    };

    this.gameApi.callbackError = (e) => {
      this.gameApi.isLoading = false;
      this.gameApi.isError = true;
      this.gameApi.error = e;
    };
    this.gameApi.callbackSuccess = (resp) => {
      this.submission(resp.Game.currentFloor, resp.Game.currentTile)
      this.gameApi.isLoading = false;
    };
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/item/updateMany";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({
        name: "PageExploreDailyGame",
      });
    };
  },
  mounted() {
    this.score = this.game.score;
    if (this.score >= 150 && this.score < 200) {
      this.star = 1;
    } else if (this.score >= 200 && this.score < 250) {
      this.star = 2;
    } else if (this.score >= 250) {
      this.star = 3;
    } else {
      this.star = 0;
    }

    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    } else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } else {
      this.schoolYear =  this.auth.User.schoolYear;
    }

    this.gameApi.params = {
      schoolYear: this.schoolYear,
    };

    this.$api.fetch(this.gameApi);
  },
  methods: {
    submission(floor,tile) {
      this.submissionApi.url =
        this.$api.servers.log +
        "/api/v1/" +
        this.$_getLocale() +
        "/dailyGameProgress/add";
      this.submissionApi.callbackReset = () => {
        this.submissionApi.isLoading = true;
        this.submissionApi.isError = false;
        this.submissionApi.error = null;
      };
      this.submissionApi.callbackError = (e) => {
        this.submissionApi.isLoading = false;
        this.submissionApi.isError = true;
        this.submissionApi.error = e;
      };
      this.submissionApi.callbackSuccess = () => {
        this.submissionApi.isLoading = false;
      };
      this.submissionApi.params = {
        institutionId: this.auth.Institution.id || null,
        classroomId: this.auth.Classroom.id || null,
        studentId: this.auth.User.id,
        schoolYear: this.schoolYear,
        mochiId: this.auth.Player.mochiId,
        currentFloor: floor,
        currentTile: tile,
      };
      this.$api.fetch(this.submissionApi);
    },
    play() {
      this.api.params = {
        data:
          '[{"action":"add","itemKey":"C000040","quantity":' + this.star + "}]",
      };
      this.$api.fetch(this.api);
    },
  },
};
</script>

    <style scoped>
.list-panel {
  margin-top: 20px;
  padding: 5px 0;
}

.list-card {
  padding: 0 10px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}

.no-boss {
  filter: brightness(0);
  opacity: 50%;
}
</style>