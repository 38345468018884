<template>
  <div
    style="min-height: calc(100vh - 40px)"
    class="justify-center align-center d-flex"
  >
    <div>
      <div class="text-center text-h4">{{ $t("string.result") }}</div>
      <div>
        <div class="d-flex text-h6 justify-center">
          <div class="pa-2">{{ $t("model.prop.score") }} :</div>
          <div class="pa-2">{{ score }}</div>
        </div>
        <div class="d-flex text-h6 justify-center">
          <div class="pa-2 d-flex">
            <v-img
              v-for="i in (1, difficulty)"
              :key="i"
              max-width="20"
              max-height="20"
              class="mt-2 mr-1"
              :src="require('@/assets/bugCatcher/DifficultyFire.png')"
            ></v-img>
          </div>
          <div class="pa-2">+ {{ difficultyScore }}</div>
        </div>
        <div class="d-flex justify-center my-2">
          <div class="mx-2">
            <v-icon :color="score > 600 ? 'yellow darken-2' : 'gray'" x-large>
              mdi-star</v-icon
            >
            <div class="text-center text-h6">600</div>
          </div>
          <div class="mx-2">
            <v-icon :color="score > 750 ? 'yellow darken-2' : 'gray'" x-large>
              mdi-star</v-icon
            >
            <div class="text-center text-h6">750</div>
          </div>
          <div class="mx-2">
            <v-icon :color="score > 900 ? 'yellow darken-2' : 'gray'" x-large>
              mdi-star</v-icon
            >
            <div class="text-center text-h6">900</div>
          </div>
        </div>
        <!-- <v-img
          v-if="bossImg"
          :src="bossImg"
          width="150"
          class="mx-auto mt-9 mb-2 d-flex align-center"
          :class="bossIsCollected ? '' : 'no-boss'"
        >
        </v-img>
        <div v-if="bossIsCollected" class="text-center">
          {{ $t("string.you_collected") }} {{ bossName }}
        </div>
        <div v-else class="text-center">{{ $t("string.you_missed") }}</div> -->

        <div class="d-flex justify-center mt-12">
          <v-btn class="ma-3" color="green lighten-1" dark @click="clickReplay">
            {{ $t("action.playAgain") }}
          </v-btn>
          <v-btn
            class="ma-3"
            color="green darken-3"
            dark
            :to="{ name: playAgain }"
          >
            {{ $t("action.done") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    "score",
    "bossIsCollected",
    "bossImg",
    "playAgain",
    "bossName",
    "difficulty",
    "difficultyScore"
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    clickReplay() {
      this.$router.push({
        name: this.playAgain,
      });
    },
  },
};
</script>

<style scoped>
.no-boss {
  filter: brightness(0);
  opacity: 50%;
}
</style>