<template>
  <div>
    <div class="text-center text-h4 mb-5">{{ $t("string.result") }}</div>
    <div>
      <div class="d-flex justify-center">
        <div class="px-2">{{ $t("string.gameScore") }} :</div>
        <div class="px-2">{{ score - 50 * (difficulty - 1) }}</div>
      </div>
      <div class="d-flex justify-center align-center">
        <div class="px-2">
          {{
            difficulty == 3
              ? $t("string.hard")
              : difficulty == 2
              ? $t("string.medium")
              : $t("string.easy")
          }}
        </div>
        <v-img
          v-for="i in difficulty"
          :key="i"
          max-width="20"
          max-height="20"
          :src="require('@/assets/bugCatcher/DifficultyFire.png')"
        ></v-img>
        <div class="ml-2">:</div>

        <div class="px-2">+ {{ 50 * (difficulty - 1) }}</div>
      </div>
      <div class="d-flex text-h5 justify-center">
        <div class="pa-2">{{ $t("string.totalScore") }} :</div>
        <div class="pa-2">{{ score }}</div>
      </div>

      <div class="d-flex justify-center my-2">
        <div class="mx-2">
          <v-icon :color="star > 0 ? 'yellow darken-2' : 'gray'" x-large>
            mdi-star</v-icon
          >
          <div class="text-center text-h6">600</div>
        </div>
        <div class="mx-2">
          <v-icon :color="star > 1 ? 'yellow darken-2' : 'gray'" x-large>
            mdi-star</v-icon
          >
          <div class="text-center text-h6">750</div>
        </div>
        <div class="mx-2">
          <v-icon :color="star > 2 ? 'yellow darken-2' : 'gray'" x-large>
            mdi-star</v-icon
          >
          <div class="text-center text-h6">900</div>
        </div>
      </div>
      <v-img
        v-if="bossImg"
        :src="bossImg"
        width="150"
        class="mx-auto mt-9 mb-2 d-flex align-center"
        :class="bossIsCollected ? '' : 'no-boss'"
      >
      </v-img>
      <div v-if="bossIsCollected" class="text-center">
        {{ $t("string.you_collected") }} {{ bossName }}
      </div>
      <div v-else class="text-center">
        {{
          series.code == "E23GAME0004"
            ? $t("string.you_missed_d")
            : series.code == "E23GAME0003"
            ? $t("string.you_missed_c")
            : series.code == "E23GAME0002"
            ? $t("string.you_missed_b")
            : $t("string.you_missed_x")
        }}
      </div>

      <div class="d-flex justify-center mt-12">
        <v-btn class="ma-3" color="green lighten-1" dark @click="clickReplay">
          {{ $t("action.playAgain") }}
        </v-btn>
        <v-btn
          class="ma-3"
          color="green darken-3"
          dark
          :to="{ name: 'PageEventLevels' }"
        >
          {{ $t("action.done") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    series: (state) => state.series.data,
  }),
  props: [
    "score",
    "bossIsCollected",
    "bossImg",
    "playAgain",
    "bossName",
    "difficulty",
    "star",
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    clickReplay() {
      this.$router.push({
        name: "PageEventLevelDetail",
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.no-boss {
  filter: brightness(0);
  opacity: 50%;
}
</style>